<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-card>
        <b-row class="mb-1">
          <b-col>
            <b-skeleton type="avatar" size="60px" class="mx-auto"></b-skeleton>
            <b-skeleton width="150px" class="mx-auto mt-1"></b-skeleton>
            <b-skeleton width="50px" class="mx-auto"></b-skeleton>
          </b-col>
        </b-row>
        <b-skeleton-table
          hide-header
          :columns="4"
          :rows="10"
          :table-props="{ bordered: false, striped: true }"
        >
        </b-skeleton-table>
      </b-card>
    </template>
    <b-card>
      <h5>
        {{ $t("field.productDisplay") }}
      </h5>
      {{ item.brandingRemark }}
      <div v-if="items.length">
        <b-table-lite class="mt-2" responsive :items="items" :fields="fields">
          <template #head()="scope">
            <div class="text-nowrap">{{ $t(scope.label) }}</div>
          </template>

          <template #cell(increasement)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(description)="data">
            <span v-if="data.item.product">
              {{ trans(data.item.product, "name", $i18n.locale) }}
              <small class="d-block text-primary">
                {{ data.item.product.productCode }}
              </small>
            </span>
          </template>

          <template #cell(image)="data">
            <img
              thumbnail
              rounded
              fluid
              class="cursor-pointer thumbnail"
              v-if="data.item.image"
              :width="`50px`"
              :src="data.item.image"
              :alt="data.field.key"
              @click="previewImage(data.item.image)"
            />
          </template>
        </b-table-lite>
      </div>

      <no-record v-else />
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BImg,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BTableLite,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import TableFields from "./tableFields";
import NoRecord from "@/components/NoRecord.vue";
import callPlanState from "@/data/callPlanState";

const CallPlanRepository = Repository.get("callPlan");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BImg,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTableLite,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,

    NoRecord,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: true,
      items: [],

      callPlanState,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      CallPlanRepository.showProductDisplay(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data];
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
  setup() {
    const fields = TableFields;

    return {
      fields,
    };
  },
};
</script>
<template>
  <div>
    <load-profile></load-profile>
    <b-row class="mb-1" v-if="data.id">
      <b-col class="text-right">
        <b-button-group size="sm">
          <b-button
            variant="secondary"
            :disabled="!data.prevId || loading"
            @click="goTo(data.prevId)"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!data.nextId || loading"
            @click="goTo(data.nextId)"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-row class="mb-1">
            <b-col>
              <b-skeleton
                type="avatar"
                size="60px"
                class="mx-auto"
              ></b-skeleton>
              <b-skeleton width="150px" class="mx-auto mt-1"></b-skeleton>
              <b-skeleton width="50px" class="mx-auto"></b-skeleton>
            </b-col>
          </b-row>
          <b-skeleton-table
            hide-header
            :columns="6"
            :rows="3"
            :table-props="{ bordered: false, striped: true }"
          >
          </b-skeleton-table>

          <b-skeleton-table
            hide-header
            :columns="6"
            :rows="3"
            :table-props="{ bordered: false, striped: true }"
          >
          </b-skeleton-table>
        </b-card>
      </template>
      <b-card v-if="data.id">
        <b-row class="mb-2">
          <b-col class="text-center">
            <b-avatar
              size="60"
              class="avatar-border mb-1"
              variant="light-primary"
              :text="avatarText(data.salespersonName)"
              :src="data.salesperson ? data.salesperson.image : ''"
            >
              <feather-icon
                v-if="!data.salespersonName"
                icon="UserIcon"
                size="32"
              />
            </b-avatar>
            <h3 class="mb-0">
              {{ data.salespersonName }}
            </h3>
            <small class="d-block text-primary">
              {{ data.salespersonIdCard }}
            </small>
          </b-col>
        </b-row>

        <b-table-simple
          responsive
          borderless
          striped
          class="table-information mb-0"
        >
          <b-tbody>
            <b-tr>
              <b-td>
                {{ $t("field.visitedDate") }}
              </b-td>
              <b-td>
                {{ data.visitedDate | formatDate("LL") }}
              </b-td>
              <b-td>
                {{ $t("field.checkinTime") }}
              </b-td>
              <b-td>
                {{ data.checkinDate | formatDate("LT") }}
              </b-td>
              <b-td>
                {{ $t("field.checkoutTime") }}
              </b-td>
              <b-td>
                {{ data.checkoutDate | formatDate("LT") }}
              </b-td>
              <b-td>
                {{ $t("field.type") }}
              </b-td>
              <b-td>
                <b-badge
                  pill
                  variant="success"
                  badge-glow
                  v-if="data.isOnRoute"
                >
                  {{ $t(`field.onRoute`) }}
                </b-badge>
                <b-badge pill variant="danger" badge-glow v-else>
                  {{ $t(`field.offRoute`) }}
                </b-badge>
              </b-td>
              <b-td>
                {{ $t("field.status") }}
              </b-td>
              <b-td>
                <b-badge
                  pill
                  variant="success"
                  badge-glow
                  v-if="data.status == 2"
                >
                  {{ $t(`field.finished`) }}
                </b-badge>
                <b-badge pill variant="primary" badge-glow v-else>
                  {{ $t(`field.inProgress`) }}
                </b-badge>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <h5 class="mt-3">{{ $t("field.outlet") }}</h5>
        <b-table-simple
          responsive
          borderless
          striped
          class="table-information mb-0"
        >
          <b-tbody>
            <b-tr>
              <b-td> {{ $t("field.region") }}: </b-td>
              <b-td>
                {{ data.regionCode }}
              </b-td>
              <b-td> {{ $t("field.agent") }}: </b-td>
              <b-td>
                {{ data.agentCode }}
              </b-td>
              <b-td> {{ $t("field.outletCode") }}: </b-td>
              <b-td>
                {{ data.outletCode }}
              </b-td>
              <b-td> {{ $t("field.name") }}: </b-td>
              <b-td>
                {{ trans(data, "outletName", $i18n.locale) }}
              </b-td>
              <b-td> {{ $t("field.ownerName") }}: </b-td>
              <b-td>
                {{ data.outletOwnerName }}
              </b-td>
              <b-td> {{ $t("field.contactNumber") }}: </b-td>
              <b-td>
                <span v-if="data.outlet">
                  {{ data.outlet.contactNumber }}
                </span>
              </b-td>
              <b-td> {{ $t("field.address") }}: </b-td>
              <b-td>
                <b-link
                  class="link-underline"
                  :href="`https://www.google.com/maps/?q=${data.outlet.latitude},${data.outlet.longitude}`"
                  target="_blank"
                  v-if="data.outlet"
                >
                  {{ trans(data.outlet, "address", $i18n.locale) }}
                </b-link>
              </b-td>
              <b-td> {{ $t("field.channel") }}: </b-td>
              <b-td>
                <span v-if="data.outlet">
                  <b-badge
                    pill
                    variant="success"
                    badge-glow
                    v-if="data.outlet.channel"
                  >
                    {{ $t(`field.onPremise`) }}
                  </b-badge>
                  <b-badge pill variant="danger" badge-glow v-else>
                    {{ $t(`field.offPremise`) }}
                  </b-badge>
                </span>
              </b-td>
              <b-td> {{ $t("field.outletSegment") }}: </b-td>
              <b-td>
                <span v-if="data.outlet">
                  {{ trans(data.outlet, "outletSegmentName", $i18n.locale) }}
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>

      <b-tabs
        vertical
        content-class="mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-auto col-12"
        nav-class="nav-left"
        v-model="tabIndex"
      >
        <b-tab>
          <template #title>
            <feather-icon icon="BoxIcon" size="18" />
            <span class="ml-50 font-weight-bold">
              {{ $t("field.countStock") }}
            </span>
          </template>

          <count-stock :item="data" v-if="tabIndex === 0" />
        </b-tab>

        <b-tab>
          <template #title>
            <i class="tab-icon las la-ad"></i>
            <span class="ml-50 font-weight-bold">
              {{ $t("field.massBranding") }}
            </span>
          </template>

          <branding :item="data" v-if="tabIndex === 1" />
        </b-tab>

        <b-tab>
          <template #title>
            <i class="tab-icon las la-tv"></i>
            <span class="ml-50 font-weight-bold">
              {{ $t("field.productDisplay") }}
            </span>
          </template>

          <product-display :item="data" v-if="tabIndex === 2" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="ShoppingCartIcon" size="18" />
            <span class="ml-50 font-weight-bold">
              {{ $t("field.order") }}
            </span>
          </template>

          <order-detail :item="data" v-if="tabIndex === 3" />
        </b-tab>

        <b-tab>
          <template #title>
            <i class="las la-poll tab-icon"></i>
            <span class="ml-50 font-weight-bold">
              {{ $t("breadcrumb.marketSurvey") }}
            </span>
          </template>

          <market-survey v-if="tabIndex === 4" />
        </b-tab>
      </b-tabs>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BAvatar,
  BBadge,
  BButtonGroup,
  BButton,
  BLink,
  BTabs,
  BTab,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";
import OrderDetail from "./orderDetail/Index.vue";
import CountStock from "./countStock/Index.vue";
import Branding from "./branding/Index.vue";
import ProductDisplay from "./productDisplay/Index.vue";
import MarketSurvey from "./marketSurvey/Index.vue";

const CallPlanRepository = Repository.get("callPlan");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAvatar,
    BBadge,
    BButtonGroup,
    BButton,
    BLink,
    BTabs,
    BTab,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,

    NButtonLoading,

    OrderDetail,
    CountStock,
    Branding,
    ProductDisplay,
    MarketSurvey,
  },
  directives: {
    Ripple,
  },
  watch: {
    tabIndex(value) {
      this.$router.replace({
        query: {
          tabIndex: value,
        },
      });
    },
  },
  data() {
    return {
      loading: true,
      tabIndex: this.$route.query.tabIndex
        ? Number(this.$route.query.tabIndex)
        : 0,
      data: {},

      avatarText,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      CallPlanRepository.detail(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    goTo(id) {
      if (!id) {
        return;
      }
      this.$router.replace({
        name: `view-${this.route}`,
        params: {
          id: id,
        },
        query: {
          tabIndex: this.tabIndex,
        },
      });
      this.show();
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "call-plan";
    const route = "call-plan";

    return { resource, route };
  },
};
</script>
export default [
  {
    key: "increasement",
    label: "#",
    thClass: "w-50px text-center",
    tdClass: "text-center",
  },
  {
    key: "description",
    label: "field.description",
  },
  {
    key: "image",
    label: "field.image",
    thClass: "w-50px text-center",
    tdClass: "w-50px text-center",
  },
  {
    key: "quantity",
    label: "field.quantity",
    thClass: "text-center",
    tdClass: "text-center",
  },
]
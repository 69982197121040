<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-card>
        <b-row class="mb-1">
          <b-col>
            <b-skeleton type="avatar" size="60px" class="mx-auto"></b-skeleton>
            <b-skeleton width="150px" class="mx-auto mt-1"></b-skeleton>
            <b-skeleton width="50px" class="mx-auto"></b-skeleton>
          </b-col>
        </b-row>
        <b-skeleton-table
          hide-header
          :columns="6"
          :rows="3"
          :table-props="{ bordered: false, striped: true }"
        >
        </b-skeleton-table>

        <b-skeleton-table
          :columns="3"
          :rows="3"
          :table-props="{ bordered: false, striped: true }"
        >
        </b-skeleton-table>
      </b-card>
    </template>
    <b-card>
      <h5>
        {{ $t("field.order") }}
      </h5>
      <div v-if="item.noOrderReason">
        {{ trans(item.noOrderReason, "label", $i18n.locale) }}
      </div>
      <div v-if="items.length">
        <div v-for="(data, idx) in items" :key="`order-${data.id}`">
          <div v-if="idx > 0" class="saperate-link my-4" />

          <b-table-simple
            responsive
            borderless
            striped
            class="table-information mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-td>
                  {{ $t("field.orderCode") }}
                </b-td>
                <b-td>
                  {{ data.orderCode }}
                </b-td>
                <b-td>
                  {{ $t("field.status") }}
                </b-td>
                <b-td>
                  <b-badge pill badge-glow :class="data.state">
                    {{ $t(`orderState.${data.state}`) }}
                  </b-badge>
                </b-td>
                <b-td>
                  {{ $t("field.expectDeliveryDate") }}
                </b-td>
                <b-td>
                  {{ data.expectDeliveryDate | formatDate("LL") }}
                </b-td>
                <b-td>
                  {{ $t("field.contactNumber") }}
                </b-td>
                <b-td>
                  {{ data.contactNumber }}
                </b-td>
                <b-td>
                  {{ $t("field.note") }}
                </b-td>
                <b-td>
                  {{ data.note }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-table-lite
            class="mt-1"
            responsive
            :items="data.lines"
            :fields="fields"
          >
            <template #head()="scope">
              <div class="text-nowrap">{{ $t(scope.label) }}</div>
            </template>

            <template #cell(increasement)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(description)="data">
              <span v-if="data.item.product">
                {{ trans(data.item.product, "name", $i18n.locale) }}
                <small class="d-block text-primary">
                  {{ data.item.product.productCode }}
                </small>
              </span>
            </template>
          </b-table-lite>
        </div>
      </div>

      <no-record v-else />
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BTableLite,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import TableFields from "./tableFields";
import NoRecord from "@/components/NoRecord.vue";
import callPlanState from "@/data/callPlanState";

const CallPlanRepository = Repository.get("callPlan");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTableLite,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,

    NoRecord,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: true,
      data: {},
      items: [],

      callPlanState,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      CallPlanRepository.showOrder(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data];
            console.log(this.items);
            // this.data = {
            //   ...data,
            // };
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
  setup() {
    const fields = TableFields;

    return {
      fields,
    };
  },
};
</script>
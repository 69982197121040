export default [
  {
    key: "increasement",
    label: "#",
    thClass: "w-50px text-center",
    tdClass: "text-center",
  },
  {
    key: "question",
    label: "field.question",
  },
  {
    key: "answer",
    label: "field.answer",
  },
]